<template>
  <b-form-group
    label="Sipariş Tipi"
    label-for="id_com_sparepart_order_type"
  >
    <validation-provider
      #default="{ errors }"
      name="Sipariş Tipi"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_com_sparepart_order_type"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Giriniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'SparepartOrderTypeCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['sparepartOrderTypes/getSparepart_order_types']
    },
    dataItem() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('sparepartOrderTypes/sparepart_order_typesList', {
        select: [
          'com_sparepart_order_type.id AS id',
          'com_sparepart_order_type.title AS title',
        ],
      })
    },
  },
}
</script>
