<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Takip Kaydı</span>
        </template>
        <sparepart-order-form />
      </b-tab>
      <b-tab
        v-if="itemData.id"
        lazy
      >
        <template #title>
          <feather-icon icon="ListIcon" />
          Sipariş Detayları
        </template>
        <sparepart-order-lines />
      </b-tab>
      <b-tab
        v-if="itemData.id"
        lazy
      >
        <template #title>
          <feather-icon icon="ListIcon" />
          Görüşme Notları
        </template>
        <sparepart-order-interview />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import SparepartOrderForm from '@/views/Spareparts/Orders/Components/SparepartForm.vue'
import SparepartOrderLines from '@/views/Spareparts/Orders/Components/SparepartLines.vue'
import SparepartOrderInterview from '@/views/Spareparts/Orders/Components/SparepartInterview.vue'

export default {
  name: 'SparePartOrdersItemForm',
  components: {
    SparepartOrderInterview,
    SparepartOrderLines,
    SparepartOrderForm,
    Customer,
    BTabs,
    BTab,
  },
  computed: {
    itemData() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
  },
}
</script>
