<template>
  <div>
    <b-card :title="isCustomer ? 'Müşteri Görüşme Kayıtları' : 'Distribütör Görüşme Kayıtları'">
      <template v-if="isCustomer">
        <b-row>
          <b-col
            v-if="checkCustomersInterviews"
            cols="12"
            md="12"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(row,key) in dataList"
                v-if="row.id_com_customer"
                :key="key"
              >
                <div
                  class="font-weight-bolder text-primary"
                >
                  <b-row>
                    <b-col>
                      <FeatherIcon
                        icon="MessageCircleIcon"
                      />
                      Görüşme İçeriği
                    </b-col>
                  </b-row>
                </div>
                <div v-html="row.content" />
                <hr>
                <div class="text-info font-weight-bold">
                  {{ row.username }}
                  <div v-if="row.created">
                    <small class="text-muted"><feather-icon icon="EditIcon" /> {{ moment(row.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col v-else>
            <b-alert
              variant="warning"
              show
              class="mt-2"
            >
              <div class="alert-body text-center">
                <FeatherIcon icon="InfoIcon" />
                Görüşme kaydı bulunmuyor.
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </template>
      <template v-if="isDistributor">
        <b-row>
          <b-col
            v-if="checkDistributorInterviews"
            cols="12"
            md="12"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(row,key) in dataList"
                v-if="row.id_com_customer == null"
                :key="key"
              >
                <div
                  class="font-weight-bolder text-primary"
                >
                  <b-row>
                    <b-col>
                      <FeatherIcon
                        icon="MessageCircleIcon"
                      />
                      Görüşme İçeriği
                    </b-col>
                  </b-row>
                </div>
                <div v-html="row.content" />
                <hr>
                <div class="text-info font-weight-bold">
                  {{ row.username }}
                  <div v-if="row.created">
                    <small class="text-muted"><feather-icon icon="EditIcon" /> {{ moment(row.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col v-else>
            <b-alert
              variant="warning"
              show
              class="mt-2"
            >
              <div class="alert-body text-center">
                <FeatherIcon icon="InfoIcon" />
                Görüşme kaydı bulunmuyor.
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'SparepartListInterview',
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
    isDistributor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isNullCustomers: false,
      isNullDistributors: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['sparepartInterview/dataList']
    },
    checkCustomersInterviews() {
      return this.dataList.some(item => item.id_com_customer !== null && item.id_com_customer !== undefined)
    },
    checkDistributorInterviews() {
      return !this.dataList.every(item => item.id_com_customer !== null && item.id_com_customer !== undefined)
    },

  },
  watch: {

  },
  created() {

  },
  methods: {

  },
}
</script>
