<template>
  <div>
    <b-form-group
      label="Tahmini Geliş Tarihi"
      label-for="arrival_date"
    >
      <validation-provider
        #default="{ errors }"
        name="Tahmini Geliş Tarihi"
        rules="required"
      >
        <b-form-datepicker
          id="arrival_date"
          v-model="dataItem.arrival_date"
          v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'SparepartOrderLinesArrivalDateCard',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrderLines/dataItem']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>
<style scoped></style>
