<template>
  <b-form-group
    label="Stok Kartı"
    label-for="id_com_sparepart_stock_cards"
  >
    <validation-provider
      #default="{ errors }"
      name="Stok Kartı"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_com_sparepart_stock_cards"
        :options="stockData"
        label="title"
        :filterable="false"
        :clearable="false"
        :reduce="item => item.id"
        placeholder="Aramak için yazın.."
        @search="onStockCardsSearch"
      >
        <template v-slot:option="option">
          <div v-if="option.sparepart_stock_groups">
            {{ option.title }}
            <div>
              <small
                v-if="option.main_stock_groups"
                class="text-warning"
              >{{ option.main_stock_groups }} / </small><small class="text-primary">{{ option.sparepart_stock_groups }}</small>
            </div>
          </div>
          <div v-else>
            {{ option.title }}
          </div>
        </template>
        <template v-slot:selected-option="option">
          {{ option.title }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'

const _ = require('lodash')

export default {
  name: 'SparepartStockCardsSelectCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      stockData: [],
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['sparepartStockCards/dataList']
      return data
    },
    dataItem() {
      return this.$store.getters['sparepartOrderLines/dataItem']
    },
  },
  watch: {
    dataItem: {
      deep: true,
      handler(val) {
        if (val.id_com_sparepart_stock_cards) {
          this.getDataList()
        }
      },
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('sparepartStockCards/getDataList', {
        select: [
          'com_sparepart_stock_cards.id AS id',
          'com_sparepart_stock_cards.title AS title',
          'com_sparepart_stock_groups.title AS sparepart_stock_groups',
          'main_stock_groups.title AS main_stock_groups',
        ],
        where: {
          'com_sparepart_stock_cards.id': this.dataItem.id_com_sparepart_stock_cards,
        },
      })
        .then(response => {
          if (response) {
            this.stockData = response
          }
        })
    },
    onStockCardsSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.stockSearch(loading, search, this)
      }
    },
    stockSearch: _.debounce((loading, search, vm) => {
      const data = JSON.stringify({
        select: [
          'com_sparepart_stock_cards.id AS id',
          'com_sparepart_stock_cards.title AS title',
          'com_sparepart_stock_groups.title AS sparepart_stock_groups',
          'main_stock_groups.title AS main_stock_groups',
        ],
        or_like: {
          'com_sparepart_stock_cards.title': search,
          'com_sparepart_stock_cards.part_no': search,
        },
        limit: 50,
      })
      axiosIns
        .post('/data/sparepart_stock_cards', data)
        .then(response => {
          vm.stockData = response.data.data
          loading(false)
        })
        .catch(error => console.error(error))
    }, 350),

  },
}
</script>
