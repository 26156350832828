<template>
  <b-form-group
    label="Birim Fiyatı"
    label-for="list_price"
  >
    <validation-provider
      #default="{ errors }"
      name="Birim Fiyatı"
      rules="required"
    >
      <cleave
        v-model="dataItem.list_price"
        class="form-control"
        :raw="true"
        :options="options.price"
        placeholder="Giriniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import {
  BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'SparepartOrderLinesListPriceCard',
  components: {
    Cleave,
    ValidationProvider,
    BFormGroup,
  },
  data() {
    return {
      required,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrderLines/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
