<template>
  <b-sidebar
    id="sidebar-invoice-add-new-car"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Araç Kartı Ekle
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="simpleRulesCar">
        <b-form class="p-2">
          <b-row>
            <b-col md="12">
              <brands />
            </b-col>
            <b-col md="12">
              <models />
            </b-col>
            <b-col md="12">
              <hardware />
            </b-col>
            <b-col md="12">
              <model-year />
            </b-col>
            <b-col md="12">
              <colors />
            </b-col>
            <b-col md="12">
              <fuels />
            </b-col>
            <b-col md="12">
              <gears />
            </b-col>
            <b-col md="12">
              <license-plate />
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <saveButton
              :kvkk-require="false"
              :action-methods="submitForm"
            />
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BForm,
  BRow,
  BCol,
  BSidebar,
} from 'bootstrap-vue'
import Brands from '@/views/Cars/forms/Brands.vue'
import Models from '@/views/Cars/forms/Models.vue'
import Hardware from '@/views/Cars/forms/Hardware.vue'
import ModelYear from '@/views/Cars/forms/Model_year.vue'
import Colors from '@/views/Cars/forms/Colors.vue'
import Fuels from '@/views/Cars/forms/Fuels.vue'
import Gears from '@/views/Cars/forms/Gears.vue'
import LicensePlate from '@/views/Cars/forms/License_plate.vue'
import SaveButton from '@/views/Cars/forms/SaveButton.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BRow,
    BCol,
    Brands,
    Models,
    Hardware,
    ModelYear,
    Colors,
    Fuels,
    Gears,
    LicensePlate,
    SaveButton,
    ValidationObserver,
  },
  props: {
    refreshData: {
      type: Function,
    },
  },
  data() {
    return {
      required,
      locale: 'tr',
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
      submitStatus: false,
    }
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
    saveStatus() {
      return this.$store.getters['cars/getCarSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.refreshData()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.car.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.$store.commit('cars/CAR_RESET')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.car.id_com_customer = this.$route.params.id
      this.$refs.simpleRulesCar.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.car.submitStatus = true
          this.$store.dispatch('cars/carSave', this.car)
          this.refreshData()
        }
      })
    },
  },
}
</script>
