<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <sparepart-order-lines-orderk-no-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <sparepart-stock-cards-select-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <sparepart-order-lines-order-date-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <sparepart-order-lines-arrival-date-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <sparepart-order-lines-quantity-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <sparepart-order-lines-list-price-card />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body text-center">
            <feather-icon icon="InfoIcon" />
            Aşağıdaki tarih alanını parça teslim alındığında doldurmanız gereklidir.
          </div>
        </b-alert>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <sparepart-order-lines-completed-date-card />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, BAlert } from 'bootstrap-vue'
import SparepartOrderLinesOrderkNoCard from '@/views/Spareparts/Orders/Elements/OrderLines/OrderNo.vue'
import SparepartOrderLinesOrderDateCard from '@/views/Spareparts/Orders/Elements/OrderLines/OrderDateCard.vue'
import SparepartOrderLinesArrivalDateCard from '@/views/Spareparts/Orders/Elements/OrderLines/ArrivalDateCard.vue'
import SparepartOrderLinesQuantityCard from '@/views/Spareparts/Orders/Elements/OrderLines/QuantityCard.vue'
import SparepartOrderLinesListPriceCard from '@/views/Spareparts/Orders/Elements/OrderLines/ListPriceCard.vue'
import SparepartStockCardsSelectCard from '@/views/Spareparts/Orders/Elements/OrderLines/StockCards.vue'
import SparepartOrderLinesCompletedDateCard from '@/views/Spareparts/Orders/Elements/OrderLines/CompletedDateCard.vue'

export default {
  name: 'SparepartOrderModal',
  components: {
    SparepartOrderLinesCompletedDateCard,
    SparepartStockCardsSelectCard,
    SparepartOrderLinesListPriceCard,
    SparepartOrderLinesQuantityCard,
    SparepartOrderLinesArrivalDateCard,
    SparepartOrderLinesOrderDateCard,
    SparepartOrderLinesOrderkNoCard,
    BRow,
    BCol,
    BAlert,
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrderLines/dataItem']
    },
  },
}
</script>
