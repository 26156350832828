<template>
  <div>
    <b-form-group
      label="Görüşme Tipi"
      label-for="isCustomer"
    >
      <b-form-checkbox
        id="status"
        v-model="itemData.isCustomer"
        value="1"
        switch
      >
        {{ itemData.isCustomer === '1'? 'Müşteri Görüşmesi' : 'Distribütör Görüşmesi' }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'SparepartInterviewIsCustomerCheckCard',
  components: { BFormGroup, BFormCheckbox },
  computed: {
    itemData() {
      return this.$store.getters['sparepartInterview/dataItem']
    },
  },
}
</script>
