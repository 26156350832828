<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card title="Yeni Görüşme">
          <sparepart-interview-content-card />
          <sparepart-interview-is-customer-check-card />
          <b-button
            variant="primary"
            :disabled="isSending"
            @click="saveInterview"
          >
            <b-spinner
              v-if="isSending"
              small
            />
            <feather-icon
              v-if="!isSending"
              icon="SaveIcon"
            /> Kaydet
          </b-button>
        </b-card>
      </b-col>
      <b-col
        v-if="dataList.length > 0"
        cols="12"
        md="6"
      >
        <sparepart-list-interview
          :refresh-data="getDataList"
          :is-customer="true"
        />
      </b-col>
      <b-col
        v-if="dataList.length >0"
        cols="12"
        md="6"
      >
        <sparepart-list-interview
          :refresh-data="getDataList"
          :is-distributor="true"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SparepartListInterview from '@/views/Spareparts/Orders/Components/SparepartListInterview.vue'
import SparepartInterviewContentCard from '@/views/Spareparts/Orders/Elements/Interviews/ContentCard.vue'
import SparepartInterviewIsCustomerCheckCard from '@/views/Spareparts/Orders/Elements/Interviews/isCustomerCard.vue'

export default {
  name: 'SparepartOrderInterview',
  components: {
    SparepartInterviewIsCustomerCheckCard,
    SparepartInterviewContentCard,
    SparepartListInterview,
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
  },
  data() {
    return {
      isSending: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
    interview() {
      return this.$store.getters['sparepartInterview/dataItem']
    },
    dataList() {
      return this.$store.getters['sparepartInterview/dataList']
    },
    saveData() {
      return this.$store.getters['sparepartInterview/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getDataList()
        this.$store.dispatch('sparepartInterview/resetDataItem')
        this.isSending = false
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.isSending = false
      }
    },
  },
  created() {
    this.getDataList()
    this.$store.dispatch('sparepartInterview/resetDataItem')
  },
  methods: {
    getDataList() {
      return this.$store.dispatch('sparepartInterview/getDataList', {
        select: [
          'com_sparepart_interview.id AS id',
          'com_sparepart_interview.content AS content',
          'com_user.name AS username',
          'com_sparepart_interview.id_com_customer AS id_com_customer',
          'com_sparepart_interview.created AS created',
        ],
        where: {
          'com_sparepart_interview.id_com_sparepart_orders': this.$route.params.id,
        },
      })
    },
    saveInterview() {
      if (this.interview.content) {
        this.isSending = true
        this.interview.id_com_sparepart_orders = this.dataItem.id
        if (this.interview.isCustomer) {
          this.interview.id_com_customer = this.dataItem.id_com_customer
        } else { this.interview.id_com_customer = null }
        this.$store.dispatch('sparepartInterview/saveData', this.interview)
      } else {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası',
          text: 'Lütfen görüşme içeriği alanını doldurun',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
