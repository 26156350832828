<template>
  <div>
    <b-alert
      v-if="!dataItem.id"
      variant="warning"
      show
      class="mt-2"
    >
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" />
        Sipariş detay ekranı formu kaydedince açılacaktır.
      </div>
    </b-alert>
    <b-card title="Araç Kartı">
      <sparepart-order-customer-cars />
    </b-card>
    <b-card title="Marka ve Diğer Bilgiler">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <sparepart-order-work-no-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <sparepart-brands-card />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <sparepart-order-type-card />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <sparepart-location-card />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <sparepart-departments-card />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>

import {
  BCard, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import SparepartOrderCustomerCars from '@/views/Spareparts/Orders/Elements/CustomerCars.vue'
import SparepartOrderWorkNoCard from '@/views/Spareparts/Orders/Elements/WorkNoCard.vue'
import SparepartBrandsCard from '@/views/Spareparts/Orders/Elements/BrancdCard.vue'
import SparepartLocationCard from '@/views/Spareparts/Orders/Elements/LocationCard.vue'
import SparepartDepartmentsCard from '@/views/Spareparts/Orders/Elements/DepartmentCard.vue'
import SparepartOrderTypeCard from '@/views/Spareparts/Orders/Elements/OrderTypeCard.vue'

export default {
  name: 'SparepartOrderForm',
  components: {
    SparepartOrderTypeCard,
    BAlert,
    SparepartDepartmentsCard,
    SparepartLocationCard,
    SparepartBrandsCard,
    SparepartOrderWorkNoCard,
    BCard,
    BRow,
    BCol,
    SparepartOrderCustomerCars,
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
  },
}
</script>
